import React, {useCallback, useState} from 'react';
import {TabWorkWithUsCategory, TabForm} from './index';
import Separator from '../Separetor/Separator';
import IconSuccess from '../../icons/IconSuccess';
import {gdprFormSubmit} from '../../helpers/gdpr';

const SEPARATOR_COLOR = '#454545';

const TabWorkWithUs = ({tab}) => {
  const {
    design,
    development,
    form,
    marketing,
    projectBudget,
    typeOfProject,
  } = tab;

  const [isSubmit, setIsSubmit] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [invalidFields, setInvalidFields] = useState({});

  const handleSubmit = useCallback(async (event) => {
    gdprFormSubmit(event, {formId: 'WorkWithUsForm'}, async (data) => {
      try {
        const response = await fetch('/wp-json/contact-form-7/v1/contact-forms/8761/feedback', {
          body: data,
          method: 'POST'
        });

        const responseJSON = await response.json();

        if (!responseJSON.status) {
          console.log(`Error: ${responseJSON.message}`);
          throw new Error('Server error');
        }

        setSubmitMessage(responseJSON?.message);

        if (responseJSON.status !== 'mail_sent') {
          const invalidMessages = responseJSON.invalid_fields.reduce((obj, currentValue) => {
            obj[currentValue.into.replace('span.wpcf7-form-control-wrap.', '')] = currentValue.message;

            return obj;
          }, {});

          setInvalidFields(invalidMessages);
          return;
        }

        setInvalidFields({});
        setIsSubmit(true);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    });
  }, []);

  return (
    <>
      {isSubmit
        ? (
          <div className="contact-tabs__tab contact-tabs__tab--success">
            <IconSuccess /><p className="contact-tabs__success">{submitMessage}</p>
          </div>
        )
        : (
          <form className="contact-tabs__tab" onSubmit={handleSubmit} id="WorkWithUsForm">
            <TabWorkWithUsCategory category={typeOfProject} required={true} />
            <Separator color={SEPARATOR_COLOR} />
            <TabWorkWithUsCategory category={design} />
            <Separator color={SEPARATOR_COLOR} />
            <TabWorkWithUsCategory category={development} />
            <Separator color={SEPARATOR_COLOR} />
            <TabWorkWithUsCategory category={marketing} />
            <Separator color={SEPARATOR_COLOR} />
            <TabWorkWithUsCategory category={projectBudget} required={true} />
            <Separator color={SEPARATOR_COLOR} />

            <TabForm form={form} invalidFields={invalidFields} />
          </form>
        )
      }
    </>
  );
}

export default TabWorkWithUs;
