const noop = () => {};

export const gdprFormSubmit = (event, config = {}, callback = noop) => {
  event.preventDefault();

  const {formId} = config;

  const form = event.target;
  const formData = new FormData(form);
  const formDataUnique = new FormData();
  const uniqueValues = {};

  formData.forEach((value, key) => {
    if (uniqueValues[key]) {
      uniqueValues[key] += `, ${value}`;
    } else {
      uniqueValues[key] = value;
    }
  });

  for (const key in uniqueValues) {
    formDataUnique.append(key, uniqueValues[key]);
  }

  if (!window?._iub?.cons_instructions) {
    callback(formDataUnique);
    return;
  }

  const data = (() => {
    const data = {};
    for (const [key, value] of formDataUnique.entries()) {
      data[key] = value;
    }

    return data;
  })();

  window._iub.cons_instructions.push([
    'submit',
    {
      writeOnLocalStorage: false,
      form: {
        selector: document.getElementById(`#${formId}`),
        map: {
          subject: {
            full_name: 'full_name',
            email: 'email',
          },
          preferences: {
            term: 'terms',
          },
        },
      },
      consent: {
        subject: {
          id: data.email,
          first_name: data.full_name?.split(' ')?.at(0),
          last_name: data.full_name?.split(' ')?.at(1),
          full_name: data.full_name,
          email: data.email,
        },
        preferences: {
          term: true,
        },
        legal_notices: [
          {identifier: 'privacy_policy'},
          {identifier: 'cookie_policy'},
          {identifier: 'term'},
        ],
        proofs: [
          {
            content: JSON.stringify(data),
            form: event.target.outerHTML,
          },
        ],
      },
    },
    {
      success: function() {
        callback(formDataUnique);
      },
      error: function(response) {
        console.error(response);
      },
    },
  ]);
};